import {NgModule} from '@angular/core';
import {RouterModule} from "@angular/router";
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../material.module';
import {ContentManagementComponent} from './content-management.component'
import { MatTabsModule } from '@angular/material/tabs';
import {CustomDomainModule} from '@common/custom-domain/custom-domain.module';
import {SharedModule} from '../shared/shared.module';
import {MaterialNavbarModule} from '@common/core/ui/material-navbar/material-navbar.module';
import {AdHostModule} from '@common/core/ui/ad-host/ad-host.module';
import {TranslationsModule} from '@common/core/translations/translations.module';
import {FormatPipesModule} from '@common/core/ui/format-pipes/format-pipes.module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NoResultsMessageModule} from '@common/core/ui/no-results-message/no-results-message.module';
import {LoadingIndicatorModule} from '@common/core/ui/loading-indicator/loading-indicator.module';
import {AppFooterModule} from '@common/shared/app-footer/app-footer.module';
import {ImageOrIconModule} from '@common/core/ui/image-or-icon/image-or-icon.module';
import {CustomMenuModule} from '@common/core/ui/custom-menu/custom-menu.module';
import { SidenavModule } from '@common/shared/sidenav/sidenav.module';
import { UnlimitedPageViewComponent } from './unlimited/unlimited-page-view.component';
import { DfyMaterialsPageViewComponent } from './dfy-materials/dfy-materials-page-view.component';
import { TrainingPageViewComponent } from './training/training-page-view.component';
import { ResellerPageViewComponent } from './reseller/reseller-page-view.component';
import { RestrictionModalComponent } from './restriction-modal/restriction-modal.component';
import { DashboardContentComponent } from './dashboard-content/dashboard-content.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        CustomMenuModule,
        SidenavModule,
        CustomDomainModule,
        MaterialNavbarModule,
        AdHostModule,
        TranslationsModule,
        FormatPipesModule,
        NoResultsMessageModule,
        LoadingIndicatorModule,
        AppFooterModule,
        ImageOrIconModule,

        // material
        MaterialModule,
        MatTabsModule,
        MatIconModule,
        MatButtonModule,
    ],
    declarations: [
        ContentManagementComponent,
        UnlimitedPageViewComponent,
        DfyMaterialsPageViewComponent,
        TrainingPageViewComponent,
        ResellerPageViewComponent,
        RestrictionModalComponent,
        DashboardContentComponent
    ],
})
export class ContentManagementModule {
}