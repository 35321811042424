<sidenav [isFixed]="false">
  <nav>
    <div class="sidebar_logo">
      <a
          routerLink="/"
          class="logo-container img-logo-container"
          *ngIf="settings.get('branding.logo_light')"
      >
          <img class="logo" [alt]="'Site logo' | trans" [src]="settings.get('branding.logo_light')" />
      </a>
      <a routerLink="/" class="logo-container" *ngIf="!settings.get('branding.logo_light')">
          <div class="text-logo">{{ settings.get('branding.site_name') }}</div>
      </a>
    </div>
    <custom-menu
      [position]="menu"
      [vertical]="true"
      [compact]="sidenav.isCompact$ | async"
    ></custom-menu>
    <div class="version">{{ 'Version:' | trans }} {{ settings.get('version') }}</div>
  </nav>
  <main>
    <material-navbar menuPosition="dashboard" [container]="true" [showToggleButton]="true" (toggleButtonClick)="sidenav.toggleSidebarMode()" [displayLogo]="false" [transparent]="true"></material-navbar>
    <div class="content-inner">
      <div class="page-header">
        <div class="header-body container">
          <form class="inputs" [formGroup]="models">
            <div class="input-container">
              <label for="search-query" trans>Search</label>
              <input type="text" formControlName="query" id="search-query" />
            </div>

            <div class="input-container">
              <label for="project-status" trans>Status</label>
              <select formControlName="published" id="project-status">
                <option value="all" trans>All</option>
                <option [ngValue]="1" trans>Published</option>
                <option [ngValue]="0" trans>Unpublished</option>
              </select>
            </div>

            <div class="input-container">
              <label for="project-order" trans>Sort</label>
              <select formControlName="order" id="project-order">
                <option value="created_at|desc" trans>Newest</option>
                <option value="created_at|asc" trans>Oldest</option>
                <option value="name|desc" trans>A-Z</option>
                <option value="name|asc" trans>Z-A</option>
              </select>
            </div>
          </form>

          <div class="actions">
            <ng-container *ngIf="settings.get('billing.enable')">
              <!-- <a
                class="upgrade-button"
                mat-raised-button
                color="accent"
                routerLink="/billing/subscription"
                *ngIf="currentUser.isSubscribed()"
              >
                <mat-icon svgIcon="flash-on"></mat-icon>
                <span trans>Manage Subscription</span>
              </a> -->
              <!-- <a
                class="upgrade-button"
                mat-raised-button
                color="accent"
                routerLink="/billing/upgrade"
                *ngIf="!currentUser.isSubscribed()"
              >
                <mat-icon svgIcon="flash-on"></mat-icon>
                <span trans>Upgrade to Premium</span>
              </a> -->
            </ng-container>
            <a
              class="new-project-button"
              mat-raised-button
              color="accent"
              routerLink="/ultimate-wealth-builder/projects/new"
              trans
              >
              <mat-icon svgIcon="add"></mat-icon>
              <span trans>Create New Wealth Builder</span></a
            >
          </div>
        </div>
      </div>

      <section class="be-container">
        <ad-host slot="ads.dashboard_top" class="ad-host-top"></ad-host>

        <mat-tab-group color="accent" animationDuration="0">
          <mat-tab [label]="'Projects' | trans">
            <div class="tab-content projects">
              <div class="auto-height-grid template-grid">
                <ng-container *ngFor="let project of projects$ | async">
                  <div class="grid-wrapper">
                    <div class="grid-item">
                      <div class="grid-content">
                        <img
                          [src]="getProjectImage(project)"
                          (click)="openBuilder(project)"
                          alt="{{ project.name }} image"
                        />
                      </div>
                    </div>
                    <div class="grid-label">
                      <div class="title">
                        <div class="name" (click)="openBuilder(project)">{{ project.name }}</div>
                        <a class="url" [href]="getProjectUrl(project)" target="_blank">{{
                          getProjectUrl(project, true)
                        }}</a>
                        <div class="updated">{{ project.created_at | formattedDate }}</div>
                      </div>
                      <div class="actions">
                        <button type="button" [matMenuTriggerFor]="menu" mat-icon-button>
                          <mat-icon svgIcon="more-vert"></mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item (click)="openBuilder(project)" trans>Edit</button>
                          <a [href]="getProjectUrl(project)" target="_blank" mat-menu-item trans
                            >Preview</a
                          >
                          <button
                            mat-menu-item
                            (click)="openProjectSettingsModal(project)"
                            [disabled]="!currentUser.hasPermission('projects.publish')"
                            trans
                          >
                            Settings
                          </button>
                          <button mat-menu-item (click)="deleteProjectWithConfirmation(project)" trans>
                            Delete
                          </button>
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <no-results-message *ngIf="!(projects$ | async).length" svgImage="website-builder.svg">
              <span primary-text *ngIf="models.controls.query.value" trans
                >Not matching projects found.</span
              >
              <span primary-text *ngIf="!models.controls.query.value" trans
                >You have not created any projects yet.</span
              >
            </no-results-message>
          </mat-tab>

          <mat-tab
            [label]="'Domains' | trans"
            *ngIf="
              settings.get('builder.enable_custom_domains') &&
              currentUser.hasPermission('custom_domains.create')
            "
          >
            <div class="domains-tab tab-content">
              <button
                type="button"
                mat-raised-button
                color="accent"
                (click)="userDomains.openConnectDomainModal().subscribe()"
                trans
              >
                Connect Domain
              </button>

              <ul class="domains unstyled-list">
                <li class="domain" *ngFor="let domain of userDomains.domains$ | async">
                  <div class="domain-content">
                    <div class="host">{{ domain.host }}</div>
                    <div class="date">{{ domain.created_at | formattedDate }}</div>
                    <div *ngIf="domain.resource" class="domain-project">
                      <img [src]="getProjectImage(domain.resource)" alt="" />
                      <div class="name">{{ domain.resource.name }}</div>
                    </div>
                    <div *ngIf="!domain.resource" class="domain-project" trans>
                      This domain is not attached to any project.
                    </div>
                  </div>
                  <div class="domain-actions">
                    <button type="button" [matMenuTriggerFor]="menu" mat-icon-button>
                      <mat-icon svgIcon="more-vert"></mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item [matMenuTriggerFor]="projects" trans>Change Project</button>
                      <button mat-menu-item (click)="userDomains.maybeRemoveDomain(domain)" trans>
                        Remove
                      </button>
                    </mat-menu>
                    <mat-menu #projects="matMenu">
                      <div
                        mat-menu-item
                        *ngFor="let project of projects$ | async"
                        class="project-menu-item"
                        (click)="userDomains.attachDomainToProject(project, domain).subscribe()"
                      >
                        <img [src]="getProjectImage(project)" alt="" />
                        <div class="project-name">{{ project.name }}</div>
                      </div>
                    </mat-menu>
                  </div>
                </li>
              </ul>

              <no-results-message
                *ngIf="!(userDomains.domains$ | async).length"
                svgImage="custom-domain.svg"
              >
                <span primary-text trans>You have not attached any domains yet.</span>
              </no-results-message>
            </div>
          </mat-tab>
        </mat-tab-group>

        <ad-host slot="ads.dashboard_bottom" class="ad-host-bottom"></ad-host>
      </section>
    </div>
  </main>
</sidenav>

<loading-indicator [isVisible]="loading$ | async" class="overlay"></loading-indicator>
