import { Injectable, OnInit } from '@angular/core';
import { ContentManagementService } from './content-management.service';
import { finalize } from 'rxjs/operators';
import { BackendErrorResponse } from '@common/core/types/backend-error-response';
import { CurrentUser } from '@common/auth/current-user';
import { Toast } from '@common/core/ui/toast.service';
import { ContentManagement } from './types/ContentManagement';
import { BehaviorSubject } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable()
export class ContentManagementBase implements OnInit {

  protected name: string;

  public content: BehaviorSubject<SafeHtml> = new BehaviorSubject<SafeHtml>('');;

  public data: ContentManagement | null = null;

  constructor(
    public contentManagementService: ContentManagementService,
    public currentUser: CurrentUser,
    public toast: Toast,
    public sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.contentManagementService.loadingData.next(true);
    this.contentManagementService
      .get(this.name)
      .pipe(finalize(() => this.contentManagementService.loadingData.next(false)))
      .subscribe(
        (response) => {
          this.data = response?.contentManagement;
          if (this.data && Object.keys(this.data).indexOf('content') > -1) {
            this.content.next(this.sanitizer.bypassSecurityTrustHtml(this.data.content))
          }
        },
        (errResponse: BackendErrorResponse) => {
          console.log(errResponse.errors);
        }
      );
  }

  postData(payload: any) {
    let request;
    if (this.data?.id) {
      request = this.contentManagementService.update(this.data.id, {
        ...payload,
        name: this.name,
        user_id: this.currentUser.get('id')
      });
    } else {
      request = this.contentManagementService.create({
        ...payload,
        name: this.name,
        user_id: this.currentUser.get('id'),
      });
    }

    this.contentManagementService.loadingData.next(true);
    request
      .pipe(finalize(() => this.contentManagementService.loadingData.next(false)))
      .subscribe(
        (response) => {
          this.data = response?.contentManagement;
          this.toast.open('Content updated successfully.');
        },
        (errResponse: BackendErrorResponse) => {
          console.log(errResponse.errors);
        }
      );
  }
}
