import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/app-http-client.service';
import { BackendResponse } from '@common/core/types/backend-response';
import { ContentManagement } from './types/ContentManagement';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ContentManagementService {

    static BASE_URI = 'content-management';

    public loadingData = new BehaviorSubject<boolean>(false);

    loadingData$ = this.loadingData.asObservable()

    constructor(private http: AppHttpClient) {}

    public create(params: object): BackendResponse<{contentManagement: ContentManagement}> {
        return this.http.post(ContentManagementService.BASE_URI, params);
    }

    public update(id: number, params: object): BackendResponse<{contentManagement: ContentManagement}> {
        return this.http.put(`${ContentManagementService.BASE_URI}/${id}`, params);
    }

    public get(name: string): BackendResponse<{contentManagement: ContentManagement}> {
        return this.http.get(`${ContentManagementService.BASE_URI}/${name}`);
    }

}