import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ContentManagementService } from '@common/admin/content-management/content-management.service';
import { ContentManagementBase } from '@common/admin/content-management/content-management-base';
import { CurrentUser } from '@common/auth/current-user';
import { Toast } from '@common/core/ui/toast.service';
import { MatDialog } from '@angular/material/dialog';
import { RestrictionModalComponent } from '../restriction-modal/restriction-modal.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'training-view',
    templateUrl: './training-page-view.component.html',
    styleUrls: ['./training-page-view.component.scss'],
    providers: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TrainingPageViewComponent extends ContentManagementBase {
    constructor(
      public contentManagementService: ContentManagementService,
      public currentUser: CurrentUser,
      public toast: Toast,
      public dialog: MatDialog,
      public sanitizer: DomSanitizer
    ) {
      super(contentManagementService, currentUser, toast, sanitizer);
      this.name = 'training';
      // if (!currentUser.hasPermission('training.view')) {
      //  this.dialog.open(RestrictionModalComponent, {
      //    data: {
      //      'title': "OH OH! IT'S TIME TO UPGRADE!",
      //      'content': 'You Are Not Authorized to Access. Please Upgrade Your Plan to Access These Features.',
      //      'image_url': '/client/assets/images/reseller_restrict.png',
      //      'redirect_url': 'https://ultimatewealthbuilder.online/training/'
      //    }
      //  })
      // }
    }
  }

