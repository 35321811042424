<sidenav [isFixed]="false">
    <nav>
      <div class="sidebar_logo">
        <a
            routerLink="/"
            class="logo-container img-logo-container"
            *ngIf="settings.get('branding.logo_light')"
        >
            <img class="logo" [alt]="'Site logo' | trans" [src]="settings.get('branding.logo_light')" />
        </a>
        <a routerLink="/" class="logo-container" *ngIf="!settings.get('branding.logo_light')">
            <div class="text-logo">{{ settings.get('branding.site_name') }}</div>
        </a>
      </div>
      <custom-menu
        [position]="menu"
        [vertical]="true"
        [compact]="sidenav.isCompact$ | async"
      ></custom-menu>
      <div class="version">{{ 'Version:' | trans }} {{ settings.get('version') }}</div>
    </nav>
    <main>
      <material-navbar menuPosition="dashboard" [container]="true" [showToggleButton]="true" (toggleButtonClick)="sidenav.toggleSidebarMode()" [displayLogo]="false" [transparent]="true"></material-navbar>
      <div class="content-inner">
        <router-outlet></router-outlet>
      </div>
    </main>
</sidenav>