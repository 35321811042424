import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

export interface RestrictionModalContent {
    title: '',
    content: '',
    redirect_url: '',
    image_url: ''
}

@Component({
  selector: 'restriction-modal',
  templateUrl: './restriction-modal.component.html',
  styleUrls: ['./restriction-modal.component.scss']
})

export class RestrictionModalComponent {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<RestrictionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RestrictionModalContent
  ) {
    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['/'], { relativeTo: this.router.routerState.root });
    });
  }

  closeAction(): void {
    this.dialogRef.close();
  }

  upgardeAction(): void {
    window.location.href = this.data.redirect_url
  }

  goBackAction(): void {
    this.dialogRef.close();
  }
}