import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidenavComponent} from './sidenav.component';
import { TranslationsModule } from '@common/core/translations/translations.module';

@NgModule({
    declarations: [SidenavComponent],
    imports: [CommonModule, TranslationsModule],
    exports: [SidenavComponent],
})
export class SidenavModule {}
