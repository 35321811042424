import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ContentManagementService } from '@common/admin/content-management/content-management.service';
import { ContentManagementBase } from '@common/admin/content-management/content-management-base';
import { CurrentUser } from '@common/auth/current-user';
import { Toast } from '@common/core/ui/toast.service';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'dashboard-content',
    templateUrl: './dashboard-content.component.html',
    styleUrls: ['./dashboard-content.component.scss'],
    providers: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DashboardContentComponent extends ContentManagementBase {
    constructor(
      public contentManagementService: ContentManagementService,
      public currentUser: CurrentUser,
      public toast: Toast,
      public dialog: MatDialog,
      public sanitizer: DomSanitizer
    ) {
      super(contentManagementService, currentUser, toast, sanitizer);
      this.name = 'dashboard';
    }
}

