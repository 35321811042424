import {Routes} from '@angular/router';
import {AuthGuard} from '@common/guards/auth-guard.service';
import {CheckPermissionsGuard} from '@common/guards/check-permissions-guard.service';
import { UnlimitedPageViewComponent } from './unlimited/unlimited-page-view.component';
import { ContentManagementComponent } from './content-management.component';
import { ResellerPageViewComponent } from './reseller/reseller-page-view.component';
import { DfyMaterialsPageViewComponent } from './dfy-materials/dfy-materials-page-view.component';
import { TrainingPageViewComponent } from './training/training-page-view.component';
import { DashboardContentComponent } from './dashboard-content/dashboard-content.component';

export const USER_CONTENT_MANAGEMENT_ROUTES: Routes = [
    {
        path: 'dashboard',
        component: ContentManagementComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: DashboardContentComponent,
            }
        ]
    },
    {
        path: 'content-management',
        component: ContentManagementComponent,
        canActivate: [AuthGuard, CheckPermissionsGuard],
        children: [
            {
                path: 'unlimited',
                component: UnlimitedPageViewComponent,
                data: { permission: ['unlimited.view'] }
            },
            {
                path: 'reseller',
                component: ResellerPageViewComponent,
                data: { permission: ['reseller.view'] }
            },
            {
                path: 'dfy-materials',
                component: DfyMaterialsPageViewComponent,
                data: { permission: ['dfy_materials.view'] }
            },
            {
                path: 'training',
                component: TrainingPageViewComponent,
                data: { permission: ['training.view'] }
            }
        ],
    },
];