<section class="be-container">
  <div class="datatable-page-header">
      <h1 trans>
        <span trans>Dashboard</span>
      </h1>
  </div>
  <ad-host slot="ads.dashboard_top" class="ad-host-top"></ad-host>
  <div class="content-management-view">
    <div [innerHTML]="content | async" class="preview"></div>
  </div>
  <ad-host slot="ads.dashboard_bottom" class="ad-host-bottom"></ad-host>
</section>