import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { CurrentUser } from "@common/auth/current-user";
import { Settings } from "@common/core/config/settings.service";
import {MenuItem} from '@common/core/ui/custom-menu/menu-item';
import { SidenavComponent } from "@common/shared/sidenav/sidenav.component";

@Component({
    selector: 'content-management',
    templateUrl: './content-management.component.html',
    styleUrls: ['./content-management.component.scss'],
    providers: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentManagementComponent implements OnInit {
    @ViewChild(SidenavComponent, {static: true})
    sidenav: SidenavComponent;
    menu = this.generateMenu()

    constructor(
        public settings: Settings 
    ) {}

    ngOnInit() {
        this.menu = this.generateMenu()
    }

    private generateMenu() {
        const items = [
            {
                name: 'dashboard',
                icon: 'view-list',
                permission: '',
                route: 'dashboard',
                type: 'route',
                label: '',
                action: '',
                activeExact: false,
                condition: function(user: CurrentUser, settings: Settings) {}
            },
            {
                name: 'ultimate wealth builder',
                icon: 'pie-chart',
                permission: '',
                route: 'ultimate-wealth-builder',
                type: 'route',
                label: '',
                action: '',
                activeExact: false,
                condition: function(user: CurrentUser, settings: Settings) {}
            },
            {
                name: 'Wealth Generating Premium Pack',
                icon: 'assignment',
                permission: 'unlimited.view',
                route: 'unlimited',
                type: 'route',
                label: '',
                action: '',
                activeExact: false,
                condition: function(user: CurrentUser, settings: Settings) {}
            },
            
            {
                name: 'Traffic Explosion',
                icon: 'ads',
                permission: 'dfy_materials.view',
                route: 'dfy-materials',
                type: 'route',
                label: '',
                action: '',
                activeExact: false,
                condition: function(user: CurrentUser, settings: Settings) {}
            },

            {
                name: 'Partner With Me',
                icon: 'people',
                permission: 'reseller.view',
                route: 'reseller',
                type: 'route',
                label: '',
                action: '',
                activeExact: false,
                condition: function(user: CurrentUser, settings: Settings) {}
            },

            {
                name: 'training',
                icon: 'page',
                permission: 'training.view',
                route: 'training',
                type: 'route',
                label: '',
                action: '',
                activeExact: false,
                condition: function(user: CurrentUser, settings: Settings) {}
            },
            {
                name: 'support',
                icon: 'person',
                permission: '',
                route: 'https://shanuboghenterprises.freshdesk.com/support/home',
                type: 'link',
                label: '',
                action: '',
                activeExact: false,
                condition: function(user: CurrentUser, settings: Settings) {}
            }
        ];

        return items.map(item => {
            // item.type = 'route';
            item.label = item.name;
            const baseLink = item.name !== 'support' ? 'content-management/' : ''
            item.action = (item.name === 'ultimate wealth builder' || item.name === 'dashboard') ? item.route : `${baseLink}${item.route}`;
            item.activeExact = false;
            item.condition = function (user: CurrentUser, settings: Settings) {
                return true;
            };
            return item;
        }) as unknown as MenuItem[];
    }
}