<div class="modal-header">
    <h1 mat-dialog-title>{{ data.title }}</h1>
</div>
<div class="main-dia-content" mat-dialog-content>
  <div class="modal-image">
    <img [src]="data.image_url"/>
  </div>
  <div class="modal-text">
    <p>{{ data.content }}</p>
  </div>
</div>
<div class="buttons center mat-dialog-actions" mat-dialog-actions>
  <button mat-flat-button (click)="goBackAction()" color="warn">Close</button>
  <button mat-flat-button (click)="upgardeAction()" color="primary" class="pulse_button">Upgrade</button>
</div>